import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import Layout from '../components/shared/layout';
import SEO from '../components/SEO/SEO';
import { breakpoints, border } from '../utils/style';
import ButtonLink from '../components/shared/ButtonLink/index';
import { showContent } from '../components/shared/ContentWithFluidImages';
import Breadcrumbs from '../components/shared/Breadcrumbs';
import { getFile } from '../utils/system';
import TopTen from '../components/shared/TopTen';
import dictionary from '../utils/dictionary';
import RecentPosts from '../components/Content/RecentPosts';
import CookieDisableComponent from '../components/shared/CookieDisableError';

const WrapperDesktop = styled('div')`
  background-color: #141d44;
  margin-top: 25px;
  padding: 40px 30px;
  display: flex;
  border-radius: ${border.borderRadius};

  & h1 {
    height: 77px;
    padding: 0;
    margin: 0;

    font-family: Kanit;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2da5fe;
  }

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    padding: 0 12px 30px 12px;
    background-color: transparent;

    & h1 {
      height: max-content;
      margin-top: 30px;
      margin-bottom: 30px;
      font-family: Kanit;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2da5fe;
    }
  }
`;

const ContentWrapper = styled('div')`
  width: 754px;
  background-color: #141d44;
  border-radius: ${border.borderRadius};

  & .content {
    margin-top: 30px;

    & p {
      font-family: Kanit;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #ffffff;
    }

    & h2 {
      font-family: Kanit;
      font-size: 19px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #28b7fa;
    }
  }

  & .share {
    display: flex;
    margin-top: 25px;
    & > span {
      margin-right: 16px;
      font-family: Kanit;
      font-size: 19px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #28b7fa;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    width: calc(100% + 32px);
    margin-bottom: 60px;
    margin-left: -16px;
    padding: 0 16px;

    & .content {
      & p {
        font-family: Kanit;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.62;
        letter-spacing: normal;
        color: #f8f8f8;
      }
    }

    & .share {
      margin-top: 36px;
      margin-bottom: 34px;
    }
  }
`;

const FeaturedWrapper = styled('div')`
  width: 100%;
  height: auto;
  position: relative;
  max-height: 600px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: ${border.borderRadius} ${border.borderRadius} 0 0;
  .breadcrumbs {
    position: absolute;
    top: 20px;
    left: 30px;
    z-index: 1;
  }
  h1 {
    position: absolute;
    left: 100px;
    top: 70px;
    z-index: 1;
  }
  .gatsby-image-wrapper {
    height: 289px;
    border-radius: ${border.borderRadius};
    img {
      object-fit: fill;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    h1 {
      position: static;
      margin-top: 20px;
    }
    .breadcrumbs {
      position: static;
      margin: 10px 0;
    }
    .gatsby-image-wrapper {
      height: max-content;
    }
  }
`;

const NoFeaturedWrapper = styled('div')`
  .breadcrumbs,
  h1 {
    position: static;
    margin: 0 10% 30px;
  }

  @media (max-width: ${breakpoints.sm}) {
    .breadcrumbs,
    h1 {
      margin: 0 0 20px;
    }
  }
`;

const Sidebar = styled('div')`
  width: 268px;
  margin-left: auto;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

const PostTemplate = ({ data, pageContext }) => {
  const { wordpressPost: post, allWordpressPost: relatedPosts, allWordpressWpMedia } = data;
  const category = post.categories.length > 0 ? post.categories[0] : {};
  const { yoast_meta, title, content, featured_media } = post;
  const { edges: media } = allWordpressWpMedia;
  const { yoast_wpseo_title, yoast_wpseo_metadesc } = yoast_meta;
  const { breadcrumbs, page_title } = pageContext;
  const metaTitle = (yoast_meta && yoast_wpseo_title) || title + +'- ' + process.env.OWNER;
  const metaDesc = (yoast_meta && yoast_wpseo_metadesc) || '';
  const [localError, setLocalError] = useState(false);

  useEffect(() => {
    try {
      if (window.localStorage) {
        setLocalError(false);
      }
    } catch (error) {
      setLocalError(true);
    }
  });

  if (!localError) {
    return (
      <Layout postCategory={category}>
        <SEO title={metaTitle} description={metaDesc} pathname={post.path} key="seo" breadcrumbs={breadcrumbs} />
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        <WrapperDesktop>
          <ContentWrapper>
            <h1>{title}</h1>
            {getFile(featured_media) ? (
              <FeaturedWrapper key="featured-wrapper">
                <Img fluid={getFile(featured_media)} alt={featured_media.alt_text} fadeIn={false} loading="eager" />
              </FeaturedWrapper>
            ) : (
              <NoFeaturedWrapper key="no-featured-wrapper">
                <h1>{title}</h1>
              </NoFeaturedWrapper>
            )}
            <div className="content">{showContent(content, media)}</div>
            <div className="share">
              <span>{dictionary.share}: </span>
              <ButtonLink />
            </div>
          </ContentWrapper>
          <Sidebar>
            <TopTen />
          </Sidebar>
        </WrapperDesktop>

        <RecentPosts posts={relatedPosts.edges} />
      </Layout>
    );
  }

  return (<CookieDisableComponent />);
};

export default PostTemplate;

export const pageQuery = graphql`
  query($id: String!, $related: [Int], $contentImages: [Int]) {
    wordpressPost(id: { eq: $id }) {
      wordpress_id
      title
      content
      path
      date(formatString: "MMMM DD, YYYY")
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      categories {
        name
        slug
        path
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allWordpressPost(filter: { wordpress_id: { in: $related } }, limit: 4) {
      edges {
        node {
          slug
          title
          excerpt
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }

    allWordpressWpMedia(filter: { wordpress_id: { in: $contentImages } }) {
      edges {
        node {
          wordpress_id
          alt_text
          source_url
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                presentationWidth
              }
            }
          }
        }
      }
    }
  }
`;
